<template>
  <v-form>
    <h1>User Roles</h1>
    <v-row>
      <v-col cols="4">
        <v-autocomplete color="accent"
          item-text="userName"
          item-value="id"
          label="User"
          required
          v-model="userId"
          :items="users"
          :rules="[(v) => !!v || 'User is required']"
          @change="onUserChanged"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3" md="4" sm="6">
        <v-list>
          <v-subheader>Roles</v-subheader>
          <v-list-item v-for="role in displayRoles" v-bind:key="role.roleId">
            <template>
              <v-list-item-action>
                <v-checkbox
                  v-bind:false-value="false"
                  v-bind:true-value="true"
                  :disabled="disableCheckbox"
                  v-model="role.active"
                  @click="onRoleCheckChanged(role)"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ role.roleName }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import apiService from "@/_services/api-service"
import store from "@/store"

export default {
  name: "UserRoles",

  components: {
  },

  created() {
    this.fetch();
  },

  data: () => ({
    disableCheckbox: true,
    displayRoles: [],
    roles: [],
    users: [],
    userRoles: [],
    userId: 0,
  }),

  methods: {
    fetch() {
      this.getUsers();
      this.getRoles()
    },

    getUsersName(user) {
      return user.firstName + " " + user.lastName;
    },

    getRoles() {
      this.roles = [];
      apiService.get("roles", this.getRolesSuccess, this.getRolesError)
    },

    getRolesSuccess(result) {
      this.roles = result.data
    },

    getRolesError(error) {
      const notification = {
        title: "User roles",
        error: error.data,
      };
      store.commit("setResponseError", notification);
    },

    getUsers() {
      this.users = [];
      apiService.get("users", this.getUsersSuccess, this.getUsersError)
    },

    getUsersSuccess(result) {
      this.users = result.data
    },

    getUsersError(error) {
      const notification = {
        title: "Get Users",
        error: error.data,
      };
      store.commit("setResponseError", notification);
    },

    getUserRoles() {
      this.userRoles = [];
      const url = "UsersInRoles/" + this.userId
      apiService.get(url, this.getUserRolesSuccess, this.getUserRolesError)
    },

    getUserRolesSuccess(result) {
      this.userRoles = result.data
      this.resetRoles()
    },

    getUserRolesError(error) {
      const notification = {
        title: "Get User Roles",
        error: error.data,
      };
      store.commit("setResponseError", notification);
    },

    onRoleCheckChanged(role) {
      const userRole = {
        id: role.id,
        userId: role.userId,
        roleId: role.roleId,
      };

      if (role.active) {
        this.userRoles.push(userRole);
        this.create(userRole);
      } else {
        var index = -1;
        for (var i = 0; i < this.userRoles.length; i++) {
          const ur = this.userRoles[i];
          if (ur.userId === role.userId && ur.roleId === role.roleId) {
            index = i;
            break;
          }
        }
        if (index !== -1) {
          this.userRoles.splice(index, 1);
        }
        this.delete(userRole);
      }
    },

    onUserChanged() {
      this.disableCheckbox = this.userId === undefined;
      this.getUserRoles();
    },

    resetRoles() {
      // Reset all roles to false
      this.displayRoles = [];
      for (var r = 0; r < this.roles.length; r++) {
        const role = this.roles[r];
        const displayRole = {
          id: 0,
          roleId: role.id,
          roleName: role.roleName,
          userId: this.userId,
          active: false,
        };
        const userRole = this.userRoles.find((ur) => ur.roleId == role.id);
        if (userRole) {
          displayRole.id = userRole.id;
          displayRole.active = true;
        }
        this.displayRoles.push(displayRole);
      }
    },

    create(userRole) {
      const url = "UsersInRoles"
      apiService.post(url, userRole, this.createUserRoleSuccess, this.createUserRoleError)
    },

    createUserRoleSuccess() {
      const notification = {
        title: "User Role",
        status: "success",
        messages: ["Role Added"],
      };
      store.commit("setNotification", notification);
      this.getUserRoles()
    },

    createUserRoleError(error) {
      const notification = {
        title: "User Role",
        error: error.data,
      };
      store.commit("setResponseError", notification);
    },

    delete(userRole) {
      const url = "UsersInRoles/" + userRole.id 
      apiService.delete(url, this.deleteRoleSuccess, this.deleteRoleError)
    },

    deleteRoleSuccess(){
      const notification = {
        title: "User Role",
        status: "success",
        messages: ["Role Removed"],
      };
      store.commit("setNotification", notification);
      this.getUserRoles()
    },

    deleteRoleError(error) {
      const notification = {
        title: "Delete User Role",
        status: "error",
        error: error,
      };
      store.commit("setResponseError", notification)
      this.getUserRoles()
    }
  },
};
</script>

<style lang="scss" scoped>
</style>